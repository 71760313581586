import { __decorate } from 'tslib';
let WML_WINDOW;
let WML_DOCUMENT;
try {
  WML_WINDOW = window;
  WML_DOCUMENT = document;
} catch {
  WML_WINDOW = {};
  WML_DOCUMENT = {};
}
function WMLConstructorDecorator(ReversedBase) {
  return class extends ReversedBase {
    constructor(...args) {
      const props = args[0] || {};
      super(props);
      Object.entries(props).forEach(([key, value]) => {
        if (!key.startsWith('prop')) {
          this[key] = value;
        }
      });
      // @ts-ignore
      this.wmlInit?.(props);
    }
  };
}
let WMLUIGlobal = class WMLUIGlobal {
  constructor(params = {}) {
    this.framework = {
      name: detectFramework()
    };
  }
};
WMLUIGlobal = __decorate([WMLConstructorDecorator], WMLUIGlobal);
getGlobalObject().WINDMILLCODE = new WMLUIGlobal();
class WMLUIProperty {
  constructor(props = {}) {
    this.isPresent = true;
    // @ts-ignore
    this.value = "";
    this.text = "";
    this._class = "";
    this._classList = [];
    this.updateClassString = updateClassString(this, "_class", "_classList");
    this.toggleClassString = (targetClass, predicate = this.updateClassString) => {
      if (this.class.includes(targetClass)) {
        predicate(targetClass, "remove");
      } else {
        predicate(targetClass);
      }
    };
    this.style = {};
    this.click = evt => {
      evt?.stopImmediatePropagation();
    };
    Object.assign(this, {
      ...props
    });
  }
  get class() {
    return this._class;
  }
  set class(val) {
    this.updateClassString(val);
  }
}
class WMLUri {
  constructor(props) {
    let {
      scheme,
      host,
      port,
      path,
      query,
      fragment
    } = props;
    scheme ??= "https";
    this.url = new URL(`${scheme}://${host}${port ? `:${port}` : ''}`);
    if (path) this.url.pathname = path;
    if (query) this.url.search = query;
    if (fragment) this.url.hash = fragment;
  }
  // TODO or return this.url.origin?
  get domain() {
    return this.url.port === '' ? this.url.hostname : `${this.url.hostname}:${this.url.port}`;
  }
  get fqdn() {
    return `${this.url.protocol}//${this.url.hostname}${this.url.port ? `:${this.url.port}` : ''}`;
  }
  toString() {
    return this.url.toString();
  }
}
class WMLEndpoint {
  constructor(props = {}) {
    this.automate = false;
    Object.assign(this, {
      ...props
    });
  }
}
class WMLView extends WMLUIProperty {
  constructor(props = {}) {
    super();
    this.angular = {
      // ChangeDetectorRef
      cdref: null
    };
    Object.assign(this, {
      ...props
    });
  }
  /**
   * @description used in angular application for change detection
  */
  get cdref() {
    return this.angular.cdref;
  }
  /**
   * @description used in angular application for change detection
  */
  set cdref(val) {
    this.angular.cdref = val;
  }
}
class WMLRoute extends WMLView {
  constructor(props = {}) {
    super();
    this.route = "/";
    Object.assign(this, {
      ...props
    });
  }
}
class WMLMotionUIProperty extends WMLView {
  constructor(props = {}) {
    super();
    this.autoOpen = false;
    this.eventType = (() => {
      if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
        return "subject";
      }
      return "callback";
    })();
    /**
    * Necessary for animations to work properly.
    * Modify only if you know what you are doing.
    */
    this.helperStyles = {
      transform: "translate3d(0,0,0)"
    };
    this.keyFrameStyles = {};
    this.motionState = "closed";
    this.getGroupMotionState = () => {
      return this.motionState;
    };
    this.motionEndEvent = state => {};
    this.triggerMotionEndEvent = motionState => {
      motionState ??= this.motionState;
      if (this.eventType === "subject") {
        // @ts-ignore
        this.motionEndEvent.next?.(motionState);
      } else {
        this.motionEndEvent(motionState);
      }
    };
    this.motionKeyFrameEvent = keyFrame => {};
    this.triggerMotionKeyFrameEvent = keyFrame => {
      keyFrame ??= this.currentTransitionInfo.keyframe;
      if (this.eventType === "subject") {
        // @ts-ignore
        this.motionKeyFrameEvent.next?.(keyFrame);
      } else {
        this.motionKeyFrameEvent(keyFrame);
      }
    };
    this.animationEnd = evt => {
      let state = this.getGroupMotionState();
      let finalStyles = {
        "opening": this.keyFrameStyles["100%"],
        "closing": this.keyFrameStyles["0%"]
      }[state];
      this.motionState = {
        "closing": "closed",
        "opening": "open"
      }[state];
      Object.entries(finalStyles ?? {}).forEach(([key, value]) => {
        this.style[key] = value;
      });
      this.triggerMotionEndEvent();
      if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
        this.angular.cdref?.detectChanges();
      }
    };
    this.transitionEnd = evt => {
      let state = this.getGroupMotionState();
      this.currentTransitionInfo.transitionEndStyles.push(evt?.propertyName.replace(/-./g, match => match.charAt(1).toUpperCase()));
      let keyFramePropertyKeys = Object.keys(this.keyFrameStyles[this.currentTransitionInfo.keyframe]);
      if (this.motionState === "closing") {
        let keyFrameStyleKeys = Object.keys(this.keyFrameStyles).sort((a, b) => {
          return parseFloat(a) - parseFloat(b);
        });
        let previousKeyFrameIndex = keyFrameStyleKeys.findIndex(key => key === this.currentTransitionInfo.keyframe) + 1;
        let previousKeyFramePropertyKeys = Object.keys(this.keyFrameStyles[keyFrameStyleKeys[previousKeyFrameIndex]]);
        keyFramePropertyKeys = keyFramePropertyKeys.filter(key => {
          return previousKeyFramePropertyKeys.includes(key);
        });
      }
      keyFramePropertyKeys = keyFramePropertyKeys.filter(key => !key.includes("transition"));
      if (keyFramePropertyKeys.includes("borderRadius")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "borderRadius");
        keyFramePropertyKeys.splice(index, 1, ...["borderTopLeftRadius", "borderTopRightRadius", "borderBottomLeftRadius", "borderBottomRightRadius"]);
      }
      if (keyFramePropertyKeys.includes("margin")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "margin");
        keyFramePropertyKeys.splice(index, 1, ...["marginTop", "marginRight", "marginBottom", "marginLeft"]);
      }
      if (keyFramePropertyKeys.includes("padding")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "padding");
        keyFramePropertyKeys.splice(index, 1, ...["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"]);
      }
      if (keyFramePropertyKeys.includes("border")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "border");
        keyFramePropertyKeys.splice(index, 1, ...["borderTop", "borderRight", "borderBottom", "borderLeft"]);
      }
      if (keyFramePropertyKeys.includes("borderColor")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "borderColor");
        keyFramePropertyKeys.splice(index, 1, ...["borderTopColor", "borderRightColor", "borderBottomColor", "borderLeftColor"]);
      }
      if (keyFramePropertyKeys.includes("borderWidth")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "borderWidth");
        keyFramePropertyKeys.splice(index, 1, ...["borderTopWidth", "borderRightWidth", "borderBottomWidth", "borderLeftWidth"]);
      }
      if (keyFramePropertyKeys.includes("borderStyle")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "borderStyle");
        keyFramePropertyKeys.splice(index, 1, ...["borderTopStyle", "borderRightStyle", "borderBottomStyle", "borderLeftStyle"]);
      }
      if (keyFramePropertyKeys.includes("background")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "background");
        keyFramePropertyKeys.splice(index, 1, ...["backgroundImage", "backgroundPosition", "backgroundSize", "backgroundRepeat", "backgroundAttachment", "backgroundOrigin", "backgroundClip", "backgroundColor"]);
      }
      if (keyFramePropertyKeys.includes("font")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "font");
        keyFramePropertyKeys.splice(index, 1, ...["fontStyle", "fontVariant", "fontWeight", "fontSize", "lineHeight", "fontFamily"]);
      }
      if (keyFramePropertyKeys.includes("animation")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "animation");
        keyFramePropertyKeys.splice(index, 1, ...["animationName", "animationDuration", "animationTimingFunction", "animationDelay", "animationIterationCount", "animationDirection", "animationFillMode", "animationPlayState"]);
      }
      if (keyFramePropertyKeys.includes("flex")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "flex");
        keyFramePropertyKeys.splice(index, 1, ...["flexGrow", "flexShrink", "flexBasis"]);
      }
      if (keyFramePropertyKeys.includes("grid")) {
        let index = keyFramePropertyKeys.findIndex(key => key === "grid");
        keyFramePropertyKeys.splice(index, 1, ...["gridTemplateRows", "gridTemplateColumns", "gridTemplateAreas", "gridAutoRows", "gridAutoColumns", "gridAutoFlow"]);
      }
      let frameCompleted = keyFramePropertyKeys.every(key => {
        return this.currentTransitionInfo.transitionEndStyles.includes(key);
      });
      if (!frameCompleted) {
        return;
      }
      this.currentTransitionInfo.transitionEndStyles = [];
      if (["0%", "100%"].includes(this.currentTransitionInfo.keyframe)) {
        this.motionState = {
          "closing": "closed",
          "opening": "open"
        }[state];
        this.triggerMotionEndEvent();
      } else {
        let val = {
          "closing": "reverse",
          "opening": "forward"
        }[state];
        this.triggerMotionKeyFrameEvent();
        this.toggleMotionViaTransition({
          val,
          override: true
        });
      }
    };
    this.openMotion = () => this.toggleMotion("forward");
    this.closeMotion = () => this.toggleMotion("reverse");
    this.pauseMotion = () => {
      if (this.type === "animation") {
        this.style.animationPlayState = "paused";
      } else if (this.type === "transition") {
        if (this.currentTransitionInfo.playState !== "paused") {
          this.currentTransitionInfo.playState = "paused";
          let allStyles = getComputedStyle(this.getElement());
          let currentStyles = Object.fromEntries(Object.entries(this.style).map(([k, v]) => {
            return [k, allStyles[k]];
          }));
          this.currentTransitionInfo.currentStyles = {
            ...currentStyles,
            transition: allStyles["transition"]
          };
          Object.assign(this.style, {
            ...currentStyles,
            transition: "none"
          });
        }
      }
    };
    this.resumeMotion = () => {
      if (this.type === "animation") {
        this.style.animationPlayState = "running";
      } else if (this.type === "transition") {
        if (this.currentTransitionInfo.playState !== "running") {
          this.currentTransitionInfo.playState = "running";
          Object.assign(this.style, {
            ...this.currentTransitionInfo.currentStyles,
            ...this.keyFrameStyles[this.currentTransitionInfo.keyframe]
          });
        }
      }
    };
    this.toggleMotion = val => {
      if (this.type === "animation") {
        this.toggleMotionViaAnimation(val);
      } else if (this.type === "transition") {
        this.toggleMotionViaTransition({
          val
        });
      }
    };
    this.toggleMotionViaAnimation = val => {
      if (!["closed", "open"].includes(this.motionState)) {
        return;
      }
      this.style.animationName = "";
      this.style.animationDirection = "normal";
      if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
        this.cdref?.detectChanges();
      }
      this.style.animationDirection = val;
      if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
        this.cdref?.detectChanges();
      }
      // @ts-ignore
      this.motionState = {
        "forward": "opening",
        "reverse": "closing"
      }[val];
      let finalStyles = {
        "opening": this.keyFrameStyles["0%"],
        "closing": this.keyFrameStyles["100%"]
      }[this.motionState];
      Object.entries(finalStyles ?? {}).forEach(([key, value]) => {
        this.style[key] = value;
      });
      if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
        this.angular.cdref?.detectChanges();
      }
      setTimeout(() => {
        this.style.animationName = this.keyFrameName;
        if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
          this.angular.cdref?.detectChanges();
        }
      }, 100);
    };
    this.checkForDuplicateKeyFrameNames = () => {
      let name = this.keyFrameName;
      let timesKeyFrameOccurs = WMLMotionUIProperty.keyFrameNames.filter(keyFrame => name === keyFrame).length;
      if (timesKeyFrameOccurs >= 2) {
        if (timesKeyFrameOccurs === 2) {
          console.warn(`The keyFrameName '${name}' must be unique among all instances of WMLMotionUIProperty. The keyFrameStyles of this class won't be processed.`);
          WMLMotionUIProperty.keyFrameNames.push(name);
        }
        return true;
      }
      return false;
    };
    this.injectKeyFrames = () => {
      let shouldReturn = this.checkForDuplicateKeyFrameNames();
      if (shouldReturn) {
        return;
      }
      // Create a new style element
      this.currentStyleElement = WML_DOCUMENT.createElement('style');
      WML_DOCUMENT.head.appendChild(this.currentStyleElement);
      // Generate the keyframes string from the keyFrameStyles property
      let keyframes = `@keyframes ${this.keyFrameName} {`;
      for (const key in this.keyFrameStyles) {
        keyframes += `
        ${key} {
          ${Object.entries(this.keyFrameStyles[key]).map(([property, value]) => `${property}: ${value};`).join(' ')}
        }`;
      }
      keyframes += `
    }`;
      // Insert the keyframes rule into the style element
      // @ts-ignore
      let styleSheet = this.currentStyleElement.sheet;
      styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    };
    this.createKeyFrameName = () => {
      let defaultName;
      let length = WMLMotionUIProperty.keyFrameNames.length;
      do {
        defaultName = `keyframe-${length}`;
        length++;
      } while (WMLMotionUIProperty.keyFrameNames.includes(defaultName));
      this.keyFrameName = defaultName;
    };
    this.updateKeyFrames = props => {
      this.keyFrameStyles = props;
      if (this.type === "animation") {
        this.currentStyleElement?.remove();
      }
      if (this.type === "transition") {
        this.updateClassString(this.keyFrameName, "remove");
      }
      this.createKeyFrameName();
      if (this.type === "transiton") {
        this.updateClassString(this.keyFrameName);
      }
      if (this.type === "animation") {
        this.injectKeyFrames();
      }
      if (["Angular"].includes(getGlobalObject().WINDMILLCODE.framework.name)) {
        this.angular.cdref?.detectChanges();
      }
    };
    this.currentTransitionInfo = {
      keyframe: "0%",
      currentStyles: {},
      transitionEndStyles: [],
      playState: ""
    };
    this.setupTransitions = () => {
      this.updateClassString(this.keyFrameName);
    };
    this.toggleMotionViaTransition = props => {
      let {
        val,
        override
      } = props;
      if (!["closed", "open"].includes(this.motionState) && override !== true) {
        return;
      }
      // @ts-ignore
      this.motionState = {
        "forward": "opening",
        "reverse": "closing"
      }[val];
      let sortedStyles = Object.entries(this.keyFrameStyles).sort(([a], [b]) => {
        return parseFloat(a) - parseFloat(b);
      });
      let currentTransitionIndex = sortedStyles.findIndex(([key]) => key == this.currentTransitionInfo.keyframe);
      let nextTransitionIndex = {
        "opening": currentTransitionIndex + 1,
        "closing": currentTransitionIndex - 1
      }[this.motionState];
      this.currentTransitionInfo.keyframe = sortedStyles[nextTransitionIndex][0];
      Object.assign(this.style, {
        ...sortedStyles[nextTransitionIndex][1]
      });
      let currentTransitionDuration = sortedStyles[currentTransitionIndex][1].transitionDuration;
      if (this.motionState === "closing" && currentTransitionDuration) {
        this.style.transitionDuration = currentTransitionDuration;
      }
    };
    this.getElement = () => {
      return WML_DOCUMENT.getElementsByClassName(this.keyFrameName)[0];
    };
    this.getTransitionProperties = () => {
      let htmlElement = this.getElement();
      let transitionProperties = Object.entries(getComputedStyle(htmlElement)).filter(item => {
        return item[0].includes("transition");
      });
      return Object.fromEntries(transitionProperties);
    };
    Object.assign(this.style, {
      ...this.helperStyles,
      ...this.style
    });
    if (!props.keyFrameName) {
      this.createKeyFrameName();
    }
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, {
      type: "animation",
      ...Object.fromEntries(origProps)
    });
    if (this.type === "animation") {
      this.injectKeyFrames();
    } else if (this.type === "transition") {
      this.setupTransitions();
    }
    let keyframe = this.motionState === "closed" ? "0%" : "100%";
    Object.assign(this.style, this.keyFrameStyles[keyframe]);
    setTimeout(() => {
      if (this.type === "transition") {
        this.currentTransitionInfo.keyframe = keyframe;
      }
      if (this.autoOpen) {
        if (this.type === "animation") {
          this.motionState = "opening";
          this.style.animationName = this.keyFrameName;
        } else {
          this.openMotion();
        }
      }
    }, 0);
  }
  static {
    this.keyFrameNames = [];
  }
  get keyFrameName() {
    return this._keyFrameName;
  }
  set keyFrameName(name) {
    if (name) {
      if (this._keyFrameName) {
        WMLMotionUIProperty.keyFrameNames.filter(name => name != this._keyFrameName);
      }
      WMLMotionUIProperty.keyFrameNames.push(name);
      this._keyFrameName = name;
    }
  }
}
class WMLWrapper {
  constructor(props = {}) {
    this.view = new WMLView();
    Object.assign(this, {
      ...props
    });
  }
}
class WMLCustomComponent {
  constructor(props = {}) {
    Object.assign(this, {
      ...props
    });
  }
}
class WMLImage extends WMLRoute {
  constructor(props = {}) {
    super();
    this.ariaExpanded = false;
    this.onError = event => {};
    Object.assign(this, {
      ...props
    });
  }
}
class WMLE2ETarget {
  constructor(props = {}) {
    this.runningONE2E = false;
    this.data = {};
    Object.assign(this, {
      ...props
    });
  }
}
class WMLQueue {
  constructor() {
    this.queue = [];
  }
  enqueue(item) {
    this.queue.push(item);
  }
  dequeue() {
    return this.queue.shift();
  }
  isEmpty() {
    return this.queue.length === 0;
  }
  size() {
    return this.queue.length;
  }
  getElementAt(index) {
    if (index < 0 || index >= this.size()) {
      return undefined;
    }
    return this.queue[index];
  }
}
function detectFramework() {
  let detectedFramework = 'VanillaJS';
  const isReact = !!WML_DOCUMENT.querySelector?.('[data-reactroot], [data-reactid]');
  const isAngular = !!WML_WINDOW.ng || !!WML_DOCUMENT.querySelector?.('[ng-version]');
  const isVue = !!WML_WINDOW.Vue;
  const isSvelte = !!WML_DOCUMENT.querySelector?.('[class*="svelte-"]');
  const isEmber = !!WML_WINDOW.Ember || !!WML_DOCUMENT.querySelector?.('[id*="ember"]');
  const isBackbone = !!WML_WINDOW.Backbone;
  const isPreact = !!WML_WINDOW.preact || !!WML_DOCUMENT.querySelector?.('[data-preactroot]');
  const isNext = !!WML_DOCUMENT.querySelector?.('#__next') || !!WML_WINDOW.__NEXT_DATA__;
  const isNuxt = !!WML_WINDOW.__NUXT__;
  const isGatsby = !!WML_WINDOW.___gatsby;
  const isRemix = typeof WML_WINDOW.__remixManifest !== 'undefined';
  const isNest = !!WML_DOCUMENT.querySelector?.('[ng-version]') && !!WML_WINDOW.NestFactory;
  const isLit = !!WML_WINDOW.litHtml || !!WML_DOCUMENT.querySelector?.('template[shadowroot]');
  const isAlpine = !!WML_WINDOW.Alpine;
  const isMithril = !!WML_WINDOW.m || !!WML_DOCUMENT.querySelector?.('[data-mithril]');
  const isAurelia = !!WML_WINDOW.Aurelia;
  const isRiot = !!WML_WINDOW.riot;
  const isInferno = !!WML_WINDOW.Inferno;
  const isStencil = !!WML_DOCUMENT.querySelector?.('script[data-stencil-namespace]');
  if (isReact) detectedFramework = 'React';else if (isAngular) detectedFramework = 'Angular';else if (isVue) detectedFramework = 'Vue.js';else if (isSvelte) detectedFramework = 'Svelte';else if (isEmber) detectedFramework = 'Ember.js';else if (isBackbone) detectedFramework = 'Backbone.js';else if (isPreact) detectedFramework = 'Preact';else if (isNext) detectedFramework = 'Next.js';else if (isNuxt) detectedFramework = 'Nuxt.js';else if (isGatsby) detectedFramework = 'Gatsby';else if (isRemix) detectedFramework = 'Remix';else if (isNest) detectedFramework = 'NestJS';else if (isLit) detectedFramework = 'Lit';else if (isAlpine) detectedFramework = 'Alpine.js';else if (isMithril) detectedFramework = 'Mithril.js';else if (isAurelia) detectedFramework = 'Aurelia';else if (isRiot) detectedFramework = 'Riot.js';else if (isInferno) detectedFramework = 'Inferno';else if (isStencil) detectedFramework = 'Stencil';
  return detectedFramework;
}
function updateGlobal(props) {
  let origProps = Object.entries(props).filter(([key, val]) => {
    return !key.startsWith('prop');
  });
  Object.assign(getGlobalObject().WINDMILLCODE, {
    ...Object.fromEntries(origProps)
  });
  if (props.propFrameworkName) {
    getGlobalObject().WINDMILLCODE.framework.name = props.propFrameworkName;
  }
}
function getGlobalObject() {
  if (typeof globalThis !== 'undefined') {
    return globalThis; // ECMAScript standard global object
  }
  if (typeof window !== 'undefined') {
    // TODO double check if this should be returned
    return WML_WINDOW; // Browser environment
  }
  // @ts-ignore
  if (typeof global !== 'undefined') {
    // @ts-ignore
    return global; // Node.js environment
  }
  if (typeof self !== 'undefined') {
    return self; // Web Workers
  }
  return {};
}
function generateUUID(prefix = "") {
  // @ts-ignore
  return prefix + ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}
let generateClassPrefix = (prefix = "") => {
  return (val = "") => {
    if (val === "") {
      return prefix.split("_")[0];
    }
    return prefix + val + " ";
  };
};
let generateIdPrefix = (prefix = "") => {
  return (val = "") => {
    if (val === "") {
      return prefix.split("_")[0];
    }
    return prefix + val;
  };
};
function fillMissingProperties(source, target) {
  for (let key in source) {
    if (typeof source[key] === 'object' && source[key] !== null && !Array.isArray(source[key]) && typeof target[key] === 'object' && target[key] !== null && !Array.isArray(target[key])) {
      fillMissingProperties(source[key], target[key]);
    } else if (typeof source[key] !== 'object' || Array.isArray(source[key])) {
      target[key] = source[key];
    }
  }
}
let updateClassString = (obj, myClassDefault, classListDefault) => {
  return (val, type = "add") => {
    let myClass = myClassDefault;
    let classList = classListDefault;
    if (type === "add" && !obj[classList].includes(val)) {
      obj[classList].push(val);
    } else if (type === "remove") {
      obj[classList] = obj[classList].filter(targetClass => {
        return targetClass !== val;
      });
    } else if (type === "toggle") {
      if (obj[classList].includes(val)) {
        obj[classList] = obj[classList].filter(targetClass => targetClass !== val);
      } else {
        obj[classList].push(val);
      }
    } else if (type === "clear") {
      obj[classList] = [];
    }
    obj[myClass] = obj[classList].reduce((acc, x) => {
      return acc + " " + x;
    }, "");
  };
};
let toggleClassString = (targetClass, classList, predicate) => {
  if (classList.includes(targetClass)) {
    predicate(targetClass, "remove");
  } else {
    predicate(targetClass);
  }
};
/**
 * @obsolete, cant get to work properly
*/
// let WMLUIPropertyDecorator = (target:any,key:any)=>{
//   let text = target[key];
//   let uiProperty = new WMLUIProperty({
//     text
//   })
//   const getter = () => uiProperty;
//   const setter = (newValue: any) => {
//     uiProperty = newValue;
//   };
//   Object.defineProperty(target, key, {
//     get: getter,
//     set: setter,
//     enumerable: true,
//     configurable: true,
//   });
// }
let generateRandomNumber = (range = 100, additional = 0) => {
  return Math.floor(Math.random() * range) + additional;
};
let generateRandomColor = () => {
  let randomNumber = generateRandomNumber(0xFFFFFF);
  let hexColor = randomNumber.toString(16).padStart(6, '0');
  return `#${hexColor}`;
};
let selectRandomOptionFromArray = (myArray, index) => {
  return myArray[generateRandomNumber(index ?? myArray.length)];
};
const replaceValuesWithPaths = (obj, path = "", predicate = props => props.defaultAssignment) => {
  // @ts-ignore
  const newObj = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    let defaultAssignment;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      defaultAssignment = replaceValuesWithPaths(obj[key], path + key + ".", predicate);
    } else {
      defaultAssignment = path + key;
    }
    newObj[key] = predicate({
      key,
      value: obj[key],
      path,
      defaultAssignment
    });
  }
  return newObj;
};
class SingletonError extends Error {
  constructor(message) {
    super(message ?? "It seems that you are trying to perform an action which is only meant to happen once, if this is a class instance there should be only one class instance in the app");
    this.name = this.constructor.name;
  }
}
class WMLAPIError {
  constructor(props = {}) {
    this.statusCode = 500;
    this.msg = "The application is having an issue as of right now please try again later or contact support if the issue persists";
    Object.assign(this, {
      ...props
    });
  }
}
let getStartIndex = (pageNum, pageSize) => {
  return pageNum * pageSize;
};
let getEndIndex = (pageNum, pageSize) => {
  return (pageNum + 1) * pageSize;
};
let getIndexInfo = (pageNum, pageSize) => {
  return {
    startIndex: getStartIndex(pageNum, pageSize),
    endIndex: getEndIndex(pageNum, pageSize)
  };
};
let WMLAPIPaginationRequestModel = class WMLAPIPaginationRequestModel {
  constructor(props = {}) {
    this.fields = [];
    this.filter = [];
    this.sort = [];
    this.cursor = {};
    this.pageNum = 0;
    this.pageSize = 5;
    this.errorOccuredIsPresent = false;
  }
  getIndexInfo() {
    return getIndexInfo(this.pageNum, this.pageSize);
  }
};
WMLAPIPaginationRequestModel = __decorate([WMLConstructorDecorator], WMLAPIPaginationRequestModel);
let WMLAPIPaginationResponseModel = class WMLAPIPaginationResponseModel {
  constructor(props = {}) {
    this.columns = [];
    this.data = [];
    this.metadata = {};
    this.pageNum = 0;
    this.pageSize = 0;
    this.totalPages = 0;
    this.totalItems = 0;
  }
  getIndexInfo() {
    return getIndexInfo(this.pageNum, this.pageSize);
  }
  calculateCurrentState(totalPages, totalItems, pageSize) {
    let displayPageNum = this.pageNum + 1;
    if (totalItems) {
      this.pageSize = this.data.length;
      this.totalItems = totalItems;
      this.totalPages = Math.ceil(totalItems / (pageSize || this.pageSize));
    } else {
      totalPages = totalPages || displayPageNum;
      this.pageSize = this.data.length;
      this.totalPages = totalPages;
      this.totalItems = totalPages * this.pageSize;
    }
    return this;
  }
  calcSectionBasedOnPageDetails(data = [], pageSize = 1, pageNum = 1) {
    const startIndex = pageNum * pageSize;
    const endIndex = (pageNum + 1) * pageSize;
    this.pageNum = pageNum;
    this.pageSize = pageSize;
    this.totalItems = data.length;
    this.totalPages = Math.ceil(data.length / pageSize);
    this.data = data.slice(startIndex, endIndex);
  }
};
WMLAPIPaginationResponseModel = __decorate([WMLConstructorDecorator], WMLAPIPaginationResponseModel);

// TODO find a library that is framework and agnosticfriendly
let WMLStringObject = class WMLStringObject {
  constructor(props = {}) {
    this.orig = "myString";
    this.entitySuffix = "";
    this.camelCase = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.replace(/[-_\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : "").replace(/^(.)/, (_, c) => c.toLowerCase()) + suffix;
    };
    this.classify = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.replace(/[-_\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : "").replace(/^(.)/, (_, c) => c.toUpperCase()) + suffix;
    };
    this.capitalize = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() + suffix;
    };
    this.dasherize = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.replace(/([A-Z])/g, "-$1").toLowerCase().replace(/[_\s]+/g, "-") + suffix;
    };
    this.lowerCase = (stripSuffix = true, suffix = "") => {
      return (stripSuffix ? this.prefix : this.orig).toLowerCase() + suffix;
    };
    this.pascalCase = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.replace(/[-_\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : "").replace(/^(.)/, (_, c) => c.toUpperCase()) + suffix;
    };
    this.upperCase = (stripSuffix = true, suffix = "") => {
      return (stripSuffix ? this.prefix : this.orig).toUpperCase() + suffix;
    };
    this.titleCase = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.replace(/[-_\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : "").replace(/^(.)/, (_, c) => c.toUpperCase()) + suffix;
    };
    this.snakeCase = (stripSuffix = true, suffix = "") => {
      const str = stripSuffix ? this.prefix : this.orig;
      return str.replace(/([A-Z])/g, "_$1").toLowerCase().replace(/[-\s]+/g, "_") + suffix;
    };
    Object.assign(this, props);
  }
  get prefix() {
    return this.orig.split(this.entitySuffix)[0];
  }
};
WMLStringObject = __decorate([WMLConstructorDecorator], WMLStringObject);

/*
 * Public API Surface of wml-components-base
 */

/**
 * Generated bundle index. Do not edit.
 */

export { SingletonError, WMLAPIError, WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel, WMLConstructorDecorator, WMLCustomComponent, WMLE2ETarget, WMLEndpoint, WMLImage, WMLMotionUIProperty, WMLQueue, WMLRoute, WMLStringObject, WMLUIGlobal, WMLUIProperty, WMLUri, WMLView, WMLWrapper, WML_DOCUMENT, WML_WINDOW, detectFramework, fillMissingProperties, generateClassPrefix, generateIdPrefix, generateRandomColor, generateRandomNumber, generateUUID, getGlobalObject, replaceValuesWithPaths, selectRandomOptionFromArray, toggleClassString, updateClassString, updateGlobal };
